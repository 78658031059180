import React, { lazy } from "react";

import { Route } from "react-router-dom";

import publicContentRoutes from "./public-content/public-content-routes";
import SecuredContent from "./secure-content/secured-content";
import App from "../../App";



const HomePage = lazy(() => import("./home-page/home-page"));
const NotFound = lazy(() => import("./not-found/not-found"));
const FindCourses = lazy(() => import("./courses/find-courses-page/find-courses-page"));
const SignInHandler = lazy(() => import ('./sign-in-handler/sign-in-handler'));
const SignupPage = lazy(() => import('./signup/signup-page'));
const FindClasses = lazy(() => import("./class/find-classes-page/find-classes-page"));
const FindInstitutePage =
  lazy(() => import("./institutes/find-institute-page/find-institute-page"));
const StudentPage = lazy(() => import("./student/student-page"));
const NewsRoom = lazy(() => import("./news-room/news-room-page"));
const ProgramPage = lazy(() => import("./institutes/program-page"));
const HubPage = lazy(() => import("./hubs/hub-page"));
const NewStudentPage = lazy(() => import("./student/new-student/new-student"));
const AdminAlertsPage = lazy(() => import("./admin/alerts/admin-alerts-page"));
const ProgramAdminPage = lazy(() => import("./program-admin/program-admin-page"));

//new route provider stuff needs just the routes without the suspense stuff
//in the index.js, which feels annoying, but I don't see a way around it
const AppRoutes =  (<Route exact path='/' element={<App />}>
  <Route index element={<HomePage/>}/>
  <Route exact path="/courses" element={<FindCourses />} />
  <Route exact path="/newsRoom" element={<NewsRoom />} />
  <Route exact path="/findClasses" element={<FindClasses />} />
  <Route path="/findInstitute/*" element={<FindInstitutePage />} />
  {/*SignInHandler looks at storage to find where we redirect once we have
an actual user loaded. It is redirected to by the site when sign in is completed */}
  <Route exact path="/onsignin" element={<SignInHandler />} />
  {/*Note: signupPage has subroutes for quick register and success */}
  <Route exact path="/signup/:classId/*" element={<SignupPage />} />
  {/*routes to specific program pages are found in programs/program-routes*/}
  <Route path="/institute/:programId/*" element={<ProgramPage/>}/>
  {/*routes to specific hub pages are found in hubs/hub-routes*/}
  <Route path="/hub/:hubId/*" element={<HubPage/>}/>
  {/*routes to specific student pages are found in student/student-routes*/}
  <Route path="/student/*" element={<SecuredContent><StudentPage /></SecuredContent>} />
  {publicContentRoutes}
  <Route path="/newStudent" element={<SecuredContent><NewStudentPage/></SecuredContent>}/>
  <Route path="/admin" element={<AdminAlertsPage/>}/>
  <Route path="/*" element={<NotFound />} />
  <Route path="/pgmadmin/*" element={<SecuredContent>
    <ProgramAdminPage/>
  </SecuredContent>}/>
</Route>)

//todo: we can remove SecureTest someday when we get
//some real secured routes in place.  Until then, this lets us test things.

export default AppRoutes;
