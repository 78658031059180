import { useCallback, useEffect, useRef, useState } from "react";

import useStatus from "../../generic/hooks/use-status/use-status";
import useSwitch from "../../generic/hooks/use-switch/use-switch";
import releaseNotesService from "../top-bar/release-notes-service";

const defaultService = releaseNotesService();

const useTopBarMenu = ({ service = defaultService }) => {
  const {
    status: releaseNotesStatus,
    setSavingStatus: loading,
    setErrorStatus: error,
    setResetStatus: clearStatus,
  } = useStatus({saving:true});

  const [releaseNotes, setReleaseNotes] = useState([]);
  const [releaseNotesOpen, openReleaseNotes, closeReleaseNotes] = useSwitch();
  const [profileOpen, openProfile, closeProfile ] = useSwitch();
  const [languageOpen, openLanguage, closeLanguage] = useSwitch();
  const langRef = useRef();
  const profileRef = useRef();

  const load = useCallback(async () => {
    const notes = await service.checkReleaseNotes();
    setReleaseNotes(notes);
  }, [service]);

  const onReleaseNotesOpen = useCallback(async () => {
    openReleaseNotes();
    if (!releaseNotes?.length) {
      try {
        loading();
        const notes = await service.loadReleaseNotes();
        setReleaseNotes(notes);
        clearStatus();
      } catch (e) {
        error(e);
      }
    }
  }, [clearStatus, loading, error, openReleaseNotes, releaseNotes, service]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    releaseNotes,
    releaseNotesStatus,
    releaseNotesOpen,
    openReleaseNotes: onReleaseNotesOpen,
    closeReleaseNotes,
    profileOpen,
    openProfile,
    closeProfile,
    languageOpen,
    openLanguage,
    closeLanguage,
    langRef,
    profileRef
  };
}

export default useTopBarMenu;