import React, {useContext} from "react";

import { AppearanceSection } from "@churchofjesuschrist/eden-appearance";
import {A11y} from "@churchofjesuschrist/eden-buttons";
import Callout from "@churchofjesuschrist/eden-callout";
import {
  Profile,
  World,
  GearOutline,
  Info
} from "@churchofjesuschrist/eden-icons";
import {
  white,
  blue25,
  spacing24,
  spacing16,
  spacing8} from "@churchofjesuschrist/eden-style-constants";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import useTopBarMenu from "./use-top-bar-menus";
import useTranslation from "../../../utils/use-translations";
import FeatureFlags from "../../generic/feature-flags/feature-flags";
import LanguagesMenu from "../languages-menu/languages-menu";
import signedOnUserContext from "../master-layout/signed-on-user-context";
import ReleaseNotesModal from "../release-notes-modal/release-notes-modal";
import menuContext from "../top-bar/menu-context";
import releaseNotesService from "../top-bar/release-notes-service";
import UserProfileMenu from "../user-profile-menu/user-profile-menu";

const Button = styled(A11y)`
flex:1;
`;

const AdminBtn = styled(A11y)`
margin-right:${spacing16};
`

const MenuDivider = styled.div`
border-inline-start: 1px solid ${blue25};
margin:0 ${spacing8};
align-self:stretch;
`;

const Container = styled.div`
justify-content:flex-end;
display:flex;
`
const InnerFlexContainer = styled.div`
display:flex;
`;

const LanguageCallout = styled(Callout)`
max-width:${({isMobile}) => isMobile ? "350px" : "525px"};
`
/*callout doesn't do well with positioning if you give your contents
a width us dictating the width. Hopefully they get an update as I would like
to put in this width definition myself for a better layout*/
const LanguageMenuContainer = styled.div`
padding:${({isMobile}) => isMobile ? spacing8 : spacing16};
width:${({isMobile}) => isMobile ? "325px" : "500px"};

`;
const ProfileMenuContainer = styled.div`
padding:${({isMobile}) => isMobile ? spacing8 : spacing16};
`

const stringGroups = [
  {
    name: "shared",
    keys: ["whatsNew"]
  }
];


const defaultObj = {};
const defaultNoteService = releaseNotesService();

/*Note that our MVP does not include the search
and we probably won't ever add the grid square icon thingy back in
as it came from copy paste and has no real functionality attached to it. */
const TopBarMenus = ({ flagService, noteService = defaultNoteService }) => {
  const { managedStrings } = useTranslation(stringGroups);
  const {
    releaseNotes,
    releaseNotesOpen,
    releaseNotesStatus,
    closeReleaseNotes,
    openReleaseNotes,
    profileOpen,
    openProfile,
    closeProfile,
    languageOpen,
    openLanguage,
    closeLanguage,
    langRef,
    profileRef
  } = useTopBarMenu({ service: noteService });
  const {isMobile} = useContext(menuContext);

  const navigate = useNavigate();

  const {
    user = defaultObj,
    impersonatingUser,
  } = useContext(signedOnUserContext) ?? defaultObj;
  let canAdmin = user.canAdmin;
  if (impersonatingUser) {
    canAdmin = impersonatingUser.canAdmin;
  }

  return (<><Container data-id="top-bar-tools">
    <InnerFlexContainer>
      {releaseNotes?.length && <>
        <Button data-id="release-notes"
          onClick={openReleaseNotes}>
          <Info color={white} size={spacing24} title={managedStrings.whatsNew} />
        </Button>
        <MenuDivider />
      </>}
      {canAdmin && <FeatureFlags flag="GlobalNotifications" service={flagService}>
        <AdminBtn data-id="admin-gear" onClick={() => navigate("/admin")}>
          <GearOutline color={white} size={spacing24} />
        </AdminBtn>
      </FeatureFlags>}
      {(releaseNotes || releaseNotes?.length) && <>
        <Button data-id="release-notes"
          onClick={openReleaseNotes}>
          <Info color={white} size={spacing24} title={managedStrings.whatsNew} />
        </Button>
        <MenuDivider />
      </>}
      <Button data-id="language" ref={langRef}
        onClick={openLanguage}>
        <World color={white} size={spacing24}/>
      </Button>
      <MenuDivider/>
      <Button data-id="profile" ref={profileRef}
        onClick={openProfile}>
        <Profile color={white} size={spacing24}/>
      </Button>
      <LanguageCallout forRef={langRef} open={languageOpen}
        onClickOutside={closeLanguage}>
        <LanguageMenuContainer isMobile={isMobile}>
          <LanguagesMenu close={closeLanguage}/>
        </LanguageMenuContainer>
      </LanguageCallout>
      {/*TODO: we would like to get rid of this. Eden has a bug about this deal
    picking up OS dark mode settings sometimes. We can take out this appearance section
    at some point when that bug fix goes out */}
      <AppearanceSection mode="light">
        <Callout forRef={profileRef} open={profileOpen}
          onClickOutside={closeProfile}>
          <ProfileMenuContainer isMobile={isMobile}>
            <UserProfileMenu close={closeProfile}/>
          </ProfileMenuContainer>
        </Callout>
      </AppearanceSection>
      {releaseNotes?.length && (<ReleaseNotesModal
        isOpen={releaseNotesOpen}
        onClose={closeReleaseNotes}
        releaseNotes={releaseNotes}
        releaseNotesStatus={releaseNotesStatus}
      />)}
    </InnerFlexContainer>
  </Container>

  </>);
}

export default TopBarMenus;