import React from "react";

import { Info, Warn,Error } from "@churchofjesuschrist/eden-alert";
import { Primary, Secondary } from "@churchofjesuschrist/eden-buttons";
import Row from "@churchofjesuschrist/eden-row";
import Stack from "@churchofjesuschrist/eden-stack";
import styled from "styled-components";

import useTranslation from "../../../../utils/use-translations";
import Modal from "../../headerless-modal/headerless-modal";

const StyledInfo = styled(Info)`
  max-width: 500px;
  max-height: 300px;
  overflow: auto;
`;
const StyledWarn = styled(Warn)`
  max-width: 500px;
  max-height: 300px;
  overflow: auto;
`;
const StyledError = styled(Error)`
  max-width: 500px;
  max-height: 300px;
  overflow: auto;
`;

const stringGroups = [
  {
    name: "shared",
    keys: ["cancel", "ok"],
  },
];

const ConfirmModal = ({
  message,
  active,
  onConfirm = () => {},
  onCancel = () => {},
  okText,
  cancelText,
  mode = "info",
  ...others
}) => {
  const { managedStrings: useStrings } = useTranslation(stringGroups);
  const UseAlert = mode === "error" ? StyledError :
    mode === "warn" ? StyledWarn : StyledInfo;
  return active ? (
    <Modal
      active={true}
      onCloseGesture={onCancel}
      {...others}
    >
      <UseAlert>
        <Stack>
          {message}
          <Row>
            <Primary
              data-id="confirm"
              onClick={onConfirm}
            >
              {okText || useStrings.ok}
            </Primary>
            <Secondary
              data-id="cancel"
              onClick={onCancel}
            >
              {cancelText || useStrings.cancel}
            </Secondary>
          </Row>
        </Stack>
      </UseAlert>
    </Modal>
  ) : null ;
};

export default ConfirmModal;
